
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-gray-900;
  @apply bg-[#eff3f4];
  font-family: "Manrope", sans-serif, Roboto;
}

*, *::before, *::after {
  box-sizing: border-box;
}

h2 {
  font-size: calc(1.3203125rem + 0.74375vw);
}

.form-group {
  @apply px-1.5
}

.form-label {
  @apply block text-sm font-medium text-gray-900 mb-2;
}

.form-input {
  @apply w-full h-9;
  @apply pl-4;
  @apply bg-white border border-[#e8e8e8] rounded-lg;
  @apply outline-none;
}

.form-submit {
  @apply text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full md:w-auto px-5 py-2.5 text-center
}
.text{
  @apply text-[#171717B2];
  @apply text-sm;
}
.button{
  @apply w-full h-10 rounded-xl text-sm text-center ;
  @apply bg-[#F3F3F3];
  @apply  whitespace-nowrap;
}
.new-button{
  @apply bg-[#F3F3F3] rounded whitespace-nowrap h-9 w-24 ml-8;
}
.disabled{
  @apply text-white font-normal rounded-xl min-w-fit p-1.5 max-h-10 px-4 w-full flex-auto;
  @apply bg-gray-200;
  @apply cursor-not-allowed;
  @apply  whitespace-nowrap;
}
.button-new{
  @apply  text-white bg-[#D41A6A] hover:bg-[#930046] rounded-xl  min-w-fit font-normal  p-1.5 max-h-10 px-4 w-full flex-auto ;
  @apply  whitespace-nowrap;

}
.input-new{
  @apply p-1.5 border border-[#e8e8e8] rounded-md w-full;
}
